<template>
  <div class="container">
    <p>Please note that the information provided by this AI model is for general informational purposes only and is not
      intended to be a substitute for professional medical advice, diagnosis, or treatment. The information provided by
      this AI model is based on publicly available data sources and is not tailored to individual circumstances.
    </p>
    <p>
      It is important to consult with a licensed healthcare provider before making any decisions or taking any actions
      based on the information provided by this AI model. The use of this AI model does not establish a doctor-patient
      relationship and should not be used as a basis for diagnosis or treatment.
    </p>
    <p>
      This AI model may not be up-to-date with the latest developments in medical research and treatment options and may
      contain inaccuracies. The accuracy of the information provided by this AI model is not guaranteed, and it should not
      be relied upon as professional medical advice.
    </p>
    <p>
      By using this AI model, you acknowledge and agree that you use the information provided at your own risk and that
      Litely is not responsible for any decisions you make based on the information provided.

    </p>
</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.type;
    if (type === "app") {
      this.$store.commit("setIsApp", true);
    } else {
      this.$store.commit("setIsApp", false);
    }
  },
  mounted() { },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 70%;
  height: 100%;
  // background-color: rgba(248, 248, 248, 1);
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;
  color: #555;
  font-size: 14;
  font-weight: 500;
  line-height: 19px;

  p {
    text-align: left !important;
    margin-top: 30px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-align: left;
  }

  h4,
  h5 {
    text-align: left;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
  }
}
</style>
